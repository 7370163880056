import React, { useState } from "react";
import {
  Grid,
  Avatar,
  Typography,
  Stack,
  Button,
  useMediaQuery,
  Alert,
  Snackbar,
  IconButton,
  CircularProgress,
  Box,
} from "@mui/material";
// import QrCode2Icon from "@mui/icons-material/QrCode2";
import { WhiteBgInput, WhiteBgSelect } from "../../Component/WhiteBgInput";
import {
  CheckCircleOutline,
  EmailOutlined,
  FileDownloadOutlined,
  PersonOutline,
} from "@mui/icons-material";
import { countryCodes } from "../../Component/countryCodes";
import { useParams } from "react-router-dom";
import { CreateLeadWithoutTokenService, getAllSourceForLead, getfunnelForUser, getUserIdFromPostcardURL } from "../../Redux/Services/service";
import { GetCardByNameService, getSourceNameformFunnelAPI, sendNotificationAPI, sendNotificationForPostcardAPI } from "../../Redux/Services/MarketingServices";
import QRCode from "react-qr-code";
import { useTheme } from '@mui/material/styles';
import axios from "axios";
import { darkenHexColor } from "../../utils/color";
import { getProfileById } from "../../Redux/Services/listingService";

const ParamsPostCard = "Postcard";


const MyFunnels = () => {
  const { user_id, link } = useParams();
  const theme = useTheme();

  const Width400 = useMediaQuery("(max-width:400px)");
  const Width600 = useMediaQuery("(max-width:600px)");
  const Width900 = useMediaQuery("(max-width:900px)");

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [inputData, setInputData] = useState({});
  const [inputError, setInputError] = useState();
  const [kastDetail, setKastDetails] = React.useState({});
  const [funnelDetails, setFunnelDetails] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const [sourceId, setSourceId] = React.useState(0)
  const [userId, setUserId] = React.useState(0)



  const getLink = async () => {
    const userResponse = await getUserIdFromPostcardURL(window.location.href.split("/")[window.location.href.split("/").length - 1]);
    setUserId(userResponse.data.data[0].user_id)
  }
  React.useEffect(() => {
    getLink()
  }, [])


  const getUserDetails = async () => {
    if (user_id == ParamsPostCard) {
      getFunnelDetail(userId);
      // const card = await GetCardByNameService(user_id);
      const card = await getProfileById(userId)
      console.log(card.data.profile)
      await sendNotificationForPostcardAPI(card.data.profile[0].created_by, link)
      setKastDetails(card.data.profile.map((i) => ({ first_name: i.full_name.split(" ")[0], last_name: i.full_name.split(" ")[1], tagline: i.motto_mission_tagline, job_title: i.job_title }))[0]);
      setLoading(false);
    } else {
      const card = await GetCardByNameService(user_id);
      console.log(card.data.data[0])
      getFunnelDetail(card.data.data[0].created_by);
      setKastDetails(card.data.data[0]);
    }
  };

  const getFunnelDetail = (id) => {
    getfunnelForUser(id, link).then(val => {
      console.log("this is funnel ddetails ==> ", val.data.funnel)
      setFunnelDetails(val.data.funnel[0])
      setLoading(false);
    })
  }


  const getFunnelSourceId = async () => {
    console.log("funnel ID", funnelDetails.id)
    if (funnelDetails?.id) {
      const respo = await getSourceNameformFunnelAPI(funnelDetails?.id)
      console.log("funnel id", respo.data.data.id)
      setSourceId(respo.data.data.id)
    }
  }

  React.useEffect(() => {
    getFunnelSourceId()
  }, [funnelDetails])



  function generateAndDownloadEmptyPDF() {
    const pdfContent = `%PDF-1.3\n\n1 0 obj\n<< /Type /Catalog /Pages 2 0 R >>\nendobj\n\n2 0 obj\n<< /Type /Pages /Kids [3 0 R] /Count 1 >>\nendobj\n\n3 0 obj\n<< /Type /Page /Parent 2 0 R /MediaBox [0 0 595 842] /Contents 4 0 R >>\nendobj\n\n4 0 obj\n<< /Length 47 >>\nstream\nBT\n/F1 12 Tf\n0 0 Td\n(Empty Page) Tj\nET\nendstream\nendobj\n\n5 0 obj\n<< /Type /Font /Subtype /Type1 /Name /F1 /BaseFont /Helvetica >>\nendobj\n\nxref\n0 6\n0000000000 65535 f\n0000000010 00000 n\n0000000076 00000 n\n0000000178 00000 n\n0000000309 00000 n\n0000000390 00000 n\ntrailer\n<< /Root 1 0 R /Size 6 >>\nstartxref\n463\n%%EOF\n`;

    const blob = new Blob([pdfContent], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'funnel.pdf';
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }




  React.useEffect(() => {
    getUserDetails();
  }, [user_id, userId]);

  const [open, setOpen] = useState(false);

  const handleChangeInputValue = (e) => {
    setInputData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // validation
  const handleFunnelFormValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (!inputData?.email) {
      formIsValid = false;
      formErrors["email_error"] = "Please provide your email.";
    } else if (!/\S+@\S+\.\S+/.test(inputData?.email)) {
      formIsValid = false;
      formErrors["email_error"] = "Please provide a valid Email eg'example@example.com'.";
    }
    if (!inputData?.fullName) {
      formIsValid = false;
      formErrors["fullName_error"] = "Please provide your name.";
    }
    if (!inputData?.phone) {
      formIsValid = false;
      formErrors["phone_error"] = "please provide your contact number";
    } else if (
      !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
        inputData?.phone
      )
    ) {
      formIsValid = false;
      formErrors["phone_error"] = "Phone Number must be atleast 10 numbers";
    }

    setInputError(formErrors);
    return formIsValid;
  };

  // console.log("dunnel =>", funnelDetails);

  const funnelDetailSubmit = async () => {

    // const card = await GetCardByNameService(user_id);
    if (handleFunnelFormValidation()) {

      let sourcePostcard = 0
      if (user_id == ParamsPostCard) {
        const sourceResp = await getAllSourceForLead({ name: "Postcard/" + link });
        sourcePostcard = sourceResp.data.data[0].id;

        // const userResponse = await getUserIdFromPostcardURL({ link: window.location.href.split("/")[window.location.href.split("/").length - 1] });
        // console.log({ userResponse })
      }
      const datas = {
        type: funnelDetails.funnel_type == "Buyer" ? "B" : funnelDetails.funnel_type == "Seller" ? "S" : "O",
        lead_type: user_id == ParamsPostCard ? "W" : "C",
        first_name: inputData.fullName,
        last_name: "",
        mobile_1: inputData.phone,
        mobile_2: "",
        mobile_3: "",
        email: inputData.email,
        email_2: "",
        address_1: "",
        address_2: "",
        full_address: "",
        city: "",
        state: "",
        zip_code: "",
        source_id: user_id == ParamsPostCard ? sourcePostcard : sourceId,
        agent_id: 1,
        listing_agent: 1,
        company_attorney: 1,
        loan_officer: 1,
        insurance_agent: 1,
        label: "",
        notes: "",
        price_range: 1,
        target_city: 1,
        closing_date: "2023-03-04 19:55:02",
        bedroom: "",
        bathroom: "",
        garage: "",
        custom_tag: "",
        nurture: "",
        tag: "",
        summary: "",
        openfield1: "open",
        openfield2: "open",
        openfield3: "open",
        openfield4: "open",
        openfield5: "open",
        openfield6: "open",
        openfield7: "open",
        openfield8: "open",
        openfield9: "open",
        openfield10: "open",
        is_active: "Y",
        is_arch: "N",
        is_verified: "N",
        created_by: user_id == ParamsPostCard ? userId : parseInt(kastDetail?.created_by),
      };
      CreateLeadWithoutTokenService(datas)
        .then((res) => {
          setOpen(true);
          if (res.status == 200) {
            setInputData({});
            window.location.href = funnelDetails.pdf_link
            return;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  return (
    <>
      {loading ? <div style={{
        textAlign: "center", marginTop: 200
      }}><CircularProgress /><Typography color={"#fff"}>{"Loading your free resource."}</Typography></div> :
        <Grid container>
          <Grid item sx={{ width: "100%", backgroundColor: "#E56305" }}>
            <Typography
              color={"white"}
              textAlign={"center"}
              fontSize={15}
              padding={2}
              fontWeight={"bold"}
              className="funnelFontFamily"
            >
              {funnelDetails && funnelDetails["main_page_text"]}
            </Typography>
          </Grid>

          <Grid
            className="blueBgContainer"
            item
            container
            backgroundColor={funnelDetails?.["primary_color"] ?? "#615AE5"}
          >
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Stack direction={"row"} spacing={1}>
                <Avatar
                  sx={{ bgcolor: "transparent", mt: 1 }}
                  style={{ width: 50, height: 50 }}
                  variant="square"
                >
                  <QRCode
                    value={window.location.href}
                    size={60}
                    bgColor="rgba(0,0,0,0)"
                    fgColor="#ffffff"

                  />
                </Avatar>
                <Stack direction={"column"}>

                  <Typography
                    className="funnelFontFamily"
                    color={"white"}
                    fontSize={Width400 ? 18 : Width600 ? 20 : Width900 ? 22 : 25}
                    fontWeight={"bold"}
                    width={isMobile ? "100%" : "90%"}
                  >
                    {funnelDetails?.["headline_text"] ?? ""}
                  </Typography>
                </Stack>
              </Stack>
              <div
                style={{
                  display: Width900 ?? Width600 ?? Width400 ? "flex" : "block",
                  justifyContent: "center",
                }}
              >
                <img
                  alt="Book"
                  width={isMobile ? "100%" : "90%"}

                  src={funnelDetails?.["hero_image_upload"] ?? "/assests/book.png"}
                  style={{ marginTop: isMobile ? 10 : 20 }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} marginTop={6}>
              <Typography
                color={"#FFE499"}
                className="funnelFontFamily"
                fontSize={15}
                fontWeight={"bold"}
              >
                {funnelDetails?.["sub_headline_text"]}
              </Typography>

              <Stack spacing={2} mt={2}>
                <WhiteBgInput
                  type={"text"}
                  icon={<PersonOutline style={{ marginRight: 10 }} />}
                  placeholder="Enter full name"
                  onChange={handleChangeInputValue}
                  name="fullName"
                />
                {inputError?.fullName_error && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#ff744a",
                    }}
                  >
                    {inputError?.fullName_error}
                  </Typography>
                )}
                <WhiteBgInput
                  type={"email"}
                  name="email"
                  icon={<EmailOutlined style={{ marginRight: 10 }} />}
                  placeholder="Enter email"
                  onChange={handleChangeInputValue}
                />
                {inputError?.email_error && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#ff744a",
                    }}
                  >
                    {inputError?.email_error}
                  </Typography>
                )}
                <Stack direction={"row"} spacing={2}>
                  <WhiteBgSelect
                    options={countryCodes}
                    onChange={(e) => console.log(e.target.value)}
                    value={"+1"}
                  />
                  <WhiteBgInput
                    type={"number"}
                    name="phone"
                    placeholder="Enter mobile number"
                    onChange={handleChangeInputValue}
                  />
                </Stack>
                {inputError?.phone_error && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#ff744a",
                    }}
                  >
                    {inputError?.phone_error}
                  </Typography>
                )}

                <Button
                  sx={{
                    backgroundColor: funnelDetails?.["button_color"] ?? "#E56305",
                    color: "#fff",
                    borderRadius: 2,
                    ":hover": {
                      backgroundColor: funnelDetails?.["button_color"] ?? darkenHexColor("#E56305", 0.2),
                    },
                    fontWeight: "bold",
                    fontSize: 12,
                    textTransform: "unset",
                    padding: 2
                  }}
                  className="buttonOrange funnelFontFamily"
                  startIcon={<FileDownloadOutlined />}
                  onClick={funnelDetailSubmit}
                >
                  {funnelDetails?.["button_text"]}
                  {/* Download my free e-book */}
                </Button>
              </Stack>
            </Grid>
          </Grid>

          <Grid item container sx={{ marginTop: -17 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              sx={{
                backgroundColor: "#fff",
                paddingX: Width400 ? 1 : Width600 ? 3 : Width900 ? 4 : 6,
                paddingTop: 20,
              }}
            >
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <Avatar
                  alt="Logo"
                  src={kastDetail?.profile_photo ?? "/assests/logos.png"}
                  sx={{ width: 50, height: 50 }}
                />
                <Stack direction={"column"}>
                  {kastDetail?.first_name !== undefined ? (
                    <>
                      <Typography
                        fontWeight={"bold"}
                        fontSize={14}
                        className="funnelFontFamily"
                      >
                        {/* Jessica Wright */}
                        {`${kastDetail?.first_name}`}
                      </Typography>
                      <Typography
                        fontWeight={"bold"}
                        fontSize={12}
                        className="funnelFontFamily"
                      >
                        {/* Real estate agent */}
                        {kastDetail?.job_title}
                      </Typography>
                    </>
                  ) : (
                    <CircularProgress size={20} />
                  )}
                  <Typography
                    fontWeight={"bold"}
                    fontSize={12}
                    className="funnelFontFamily"
                  >
                    {kastDetail?.["tagline"]}
                    {/* “Success in real estate comes down to valuing your customer” */}
                  </Typography>
                </Stack>
              </Stack>

              <Typography
                fontWeight={"bold"}
                fontSize={17}
                marginTop={4}
                className="funnelFontFamily"
              >
                {funnelDetails?.["preview_headline"]}
                {/* What’s inside the ebook: */}
              </Typography>
              <Stack
                spacing={2.5}
                padding={Width400 ? 1 : Width600 ? 1 : Width900 ? 2 : 3}
              >
                {funnelDetails?.["preview_details"]?.split(",").map((val) => {
                  return <Stack direction={"row"} spacing={1.5} key={val}>
                    <CheckCircleOutline color="info" />
                    <Typography
                      fontWeight={"bold"}
                      fontSize={14}
                      className="funnelFontFamily"
                    >
                      {val}
                    </Typography>
                  </Stack>
                })}


              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} style={{ marginTop: -100 }}>
              <img
                alt="Book"
                width={"100%"}
                height={"100%"}
                src="/assests/home2.png"
              />
            </Grid>
          </Grid>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            autoHideDuration={6000}
            sx={{ zIndex: 100 }}
          >
            <Alert
              onClose={() => {
                setOpen(false);
              }}
              severity="success"
              sx={{ width: "100%" }}
            >
              Thanks for information will be in touch soon.
            </Alert>
          </Snackbar>
        </Grid >}
    </>

  );
};

export default MyFunnels;
