import {
  Grid,
  Input,
  Button,
  Typography,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { CreateLeadWithoutTokenService, getAllSourceForLead, GetAllSourceService, getUserIdFromPostcardURL } from "../../Redux/Services/service";
import { useLocation, useParams } from "react-router-dom";
import { GetCardByNameService, sendNotificationForPostcardAPI } from "../../Redux/Services/MarketingServices";
import QRCode from "react-qr-code";


const isMobile = window.innerWidth < 991
const isTablet = window.innerWidth < 768

const ParamsPostCard = "Postcard";

function FunnelPage2() {
  const { user_id } = useParams();
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const [inputData, setInputData] = useState({});
  const [inputError, setInputError] = useState();
  const [userId, setUserId] = React.useState(0)



  const handleChangeInputValue = (e) => {
    setInputData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    // //.log(inputData);
  };

  // validation
  const handleFunnelFormValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (!inputData?.email) {
      formIsValid = false;
      formErrors["email_error"] = "Email field is required.";
    } else if (!/\S+@\S+\.\S+/.test(inputData?.email)) {
      formIsValid = false;
      formErrors["email_error"] = "Invalid Email id.";
    }
    if (!inputData?.fullName) {
      formIsValid = false;
      formErrors["fullName_error"] = "Full Name is required";
    }
    if (!inputData?.phone) {
      formIsValid = false;
      formErrors["phone_error"] = "Phone is required";
    } else if (
      !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
        inputData?.phone
      )
    ) {
      formIsValid = false;
      formErrors["phone_error"] = "Phone Number must be atleast 10 numbers";
    }

    setInputError(formErrors);
    return formIsValid;
  };

  const funnelDetailSubmit = async () => {

    let sourcePostcard = 0
    let card = {}

    if (user_id != ParamsPostCard) {
      card = await GetCardByNameService(user_id);
    }
    if (user_id == ParamsPostCard) {
      const sourceResp = await getAllSourceForLead({ name: "Postcard" + "/Buying101Guide" });
      sourcePostcard = sourceResp.data.data[0].id


    }

    if (handleFunnelFormValidation()) {
      const datas = {
        type: "B",
        lead_type: user_id == ParamsPostCard ? "W" : "C",
        first_name: inputData.fullName,
        last_name: "",
        mobile_1: inputData.phone,
        mobile_2: "",
        mobile_3: "",
        email: inputData.email,
        email_2: "",
        address_1: "",
        address_2: "",
        full_address: "",
        city: "",
        state: "",
        zip_code: "",
        source_id: user_id == ParamsPostCard ? sourcePostcard : 28,
        agent_id: 1,
        listing_agent: 1,
        company_attorney: 1,
        loan_officer: 1,
        insurance_agent: 1,
        label: "",
        notes: "",
        price_range: 1,
        target_city: 1,
        closing_date: "2023-03-04 19:55:02",
        bedroom: "",
        bathroom: "",
        garage: "",
        custom_tag: "",
        nurture: "",
        tag: "",
        summary: "",
        openfield1: "open",
        openfield2: "open",
        openfield3: "open",
        openfield4: "open",
        openfield5: "open",
        openfield6: "open",
        openfield7: "open",
        openfield8: "open",
        openfield9: "open",
        openfield10: "open",
        is_active: "Y",
        is_arch: "N",
        is_verified: "N",
        created_by: user_id == ParamsPostCard ? userId : parseInt(card.data.data[0].created_by),
      };
      // //.log(datas);
      CreateLeadWithoutTokenService(datas)
        .then((res) => {
          setOpen(true);
          if (res.status == 200) {
            const link = document.createElement('a');
            link.href = "https://bizooda.s3.us-east-2.amazonaws.com/aaassets/Ultimate+Buyer+Guide.pdf";
            link.target = '_blank';
            link.download = 'file.pdf';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // fetch('https://bizooda.s3.us-east-2.amazonaws.com/folder_name/The+Ultimate+Buyer+Guide.pdf').then(response => {
            //   response.blob().then(blob => {

            //     const fileURL = window.URL.createObjectURL(blob);
            //     let alink = document.createElement('a');
            //     alink.href = fileURL;
            //     alink.download = 'The+Ultimate+Buyer+Guide.pdf';
            //     alink.click();
            //   })
            // })
          }
        })
        .catch((err) => {
          //.log("err", err);
        });
    }
  };

  // "/assests/crmfunnel/Funnel2/Rectangle_40.png"



  const getLink = async () => {
    const userResponse = await getUserIdFromPostcardURL(window.location.href.split("/")[window.location.href.split("/").length - 1]);
    setUserId(userResponse.data.data[0].user_id)
    await sendNotificationForPostcardAPI(userResponse.data.data[0].user_id, "Buying101Guide")

  }
  React.useEffect(() => {
    getLink()
  }, [])



  return (
    <div className="funnel-head2">

      <Typography sx={{ background: 'linear-gradient(92.79deg, #ff5b2e 6.31%, #fab140 95.19%)', textAlign: "center", color: "#fff", padding: 2 }}>
        {"Unlocking Your Dream Home: A Comprehensive Guide to Smart Home Buying Strategies"}
      </Typography>

      <div style={{}}  >
        <div className="funnel2-head-section ">
          <Grid container spacing={2} sx={{ height: "100%" }}>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{}} justifyContent={"center"} alignSelf={"center"}>
              <div
                style={{
                  color: "#000000",
                  fontWeight: 700,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="headingText"
              >
                <div
                  style={{
                    fontSize: isMobile ? 32 : "64px",
                    lineHeight: "129%",
                    textTransform: "capitalize",
                    textAlign: "center",
                    justifyContent: "center"
                  }}
                  className="headingFunnel"
                >
                  {/* 10 MISTAKES */}
                  Home Buying 101

                </div>
                <div
                  style={{ fontSize: isMobile ? 17 : "38px", marginTop: 10 }}
                >
                  <div style={{ marginTop: 20, textAlign: "center" }}>The Ultimate Guide to Buying a New House</div>
                </div>
              </div>


            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} justifyContent={"center"} alignSelf={"center"}>
              <div className="funnel2-form">
                <div
                  style={{
                    padding: "35px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="funnel-form-title" style={{ marginTop: isMobile ? 0 : 50 }}>
                    <div>Enter Your Details</div>
                    <div>to Get Instant Access</div>
                  </div>
                  <div className="form-divider"></div>

                  <div
                    className="login-input"
                    style={{
                      marginTop: 10,
                      width: "100%",
                      borderRadius: "60px",
                    }}
                  >
                    <div style={{ paddingLeft: 20 }}>
                      <svg
                        width={isMobile ? "15" : "30"}
                        height={isMobile ? "15" : "30"}
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.625 11.25C20.625 12.7418 20.0324 14.1726 18.9775 15.2275C17.9226 16.2824 16.4918 16.875 15 16.875C13.5082 16.875 12.0774 16.2824 11.0225 15.2275C9.96763 14.1726 9.375 12.7418 9.375 11.25C9.375 9.75816 9.96763 8.32742 11.0225 7.27252C12.0774 6.21763 13.5082 5.625 15 5.625C16.4918 5.625 17.9226 6.21763 18.9775 7.27252C20.0324 8.32742 20.625 9.75816 20.625 11.25Z"
                          fill="black"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15ZM15 1.875C12.5283 1.87513 10.1069 2.57318 8.0145 3.8888C5.92207 5.20442 4.24366 7.08414 3.17243 9.31161C2.10121 11.5391 1.68072 14.0238 1.95937 16.4797C2.23802 18.9356 3.20447 21.2629 4.7475 23.1937C6.07875 21.0487 9.00938 18.75 15 18.75C20.9906 18.75 23.9194 21.0469 25.2525 23.1937C26.7955 21.2629 27.762 18.9356 28.0406 16.4797C28.3193 14.0238 27.8988 11.5391 26.8276 9.31161C25.7563 7.08414 24.0779 5.20442 21.9855 3.8888C19.8931 2.57318 17.4717 1.87513 15 1.875Z"
                          fill="black"
                        />
                      </svg>
                    </div>

                    <div>
                      <Input
                        className="input"
                        disableUnderline
                        type="text"
                        name="fullName"
                        placeholder="Enter your Name"
                        fullWidth={true}
                        sx={{ fontSize: isMobile ? 10 : 14 }}
                        onChange={handleChangeInputValue}
                      // onChange={handleChangeInputValue}
                      />
                    </div>
                  </div>
                  {inputError?.fullName_error && (
                    <Typography
                      variant="caption"
                      sx={{
                        color: "red",
                        width: "100%",
                        textAlign: "left"
                      }}
                    >
                      {inputError?.fullName_error}
                    </Typography>
                  )}

                  <div
                    className="login-input"
                    style={{
                      marginTop: 10,
                      width: "100%",
                      borderRadius: "60px",
                    }}
                  >
                    <div style={{ paddingLeft: 20 }}>
                      <svg
                        width={isMobile ? "15" : "30"}
                        height={isMobile ? "11" : "23"}
                        viewBox="0 0 30 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30 2.875C30 1.29375 28.65 0 27 0H3C1.35 0 0 1.29375 0 2.875V20.125C0 21.7062 1.35 23 3 23H27C28.65 23 30 21.7062 30 20.125V2.875ZM27 2.875L15 10.0625L3 2.875H27ZM27 20.125H3V5.75L15 12.9375L27 5.75V20.125Z"
                          fill="black"
                        />
                      </svg>
                    </div>

                    <div>
                      <Input
                        className="input"
                        disableUnderline
                        type="text"
                        name="email"
                        placeholder="Enter Your Email"
                        fullWidth={true}
                        sx={{ fontSize: isMobile ? 10 : 14 }}
                        onChange={handleChangeInputValue}
                      />
                    </div>
                  </div>

                  {inputError?.email_error && (
                    <Typography
                      variant="caption"
                      sx={{
                        color: "red",
                        width: "100%",
                        textAlign: "left"
                      }}
                    >
                      {inputError?.email_error}
                    </Typography>
                  )}

                  <div
                    className="login-input"
                    style={{
                      marginTop: 10,
                      width: "100%",
                      borderRadius: "60px",
                    }}
                  >
                    <div style={{ paddingLeft: 20 }}>
                      <svg
                        width={isMobile ? "13" : "26"}
                        height={isMobile ? "13" : "26"}
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24.4833 26C21.3778 26 18.3507 25.3076 15.4021 23.9229C12.4526 22.5391 9.84052 20.7095 7.566 18.434C5.29052 16.1595 3.46089 13.5474 2.07711 10.5979C0.692371 7.6493 0 4.62222 0 1.51667C0 1.08333 0.144444 0.722222 0.433333 0.433333C0.722222 0.144444 1.08333 0 1.51667 0H7.36667C7.7037 0 8.00463 0.108333 8.26944 0.325C8.53426 0.541667 8.69074 0.818518 8.73889 1.15556L9.67778 6.21111C9.72593 6.54815 9.72015 6.85485 9.66044 7.13122C9.59978 7.40856 9.46111 7.65556 9.24444 7.87222L5.77778 11.4111C6.78889 13.1444 8.05278 14.7694 9.56944 16.2861C11.0861 17.8028 12.7593 19.1148 14.5889 20.2222L17.9833 16.8278C18.2 16.6111 18.4831 16.4484 18.8327 16.3396C19.1813 16.2317 19.5241 16.2019 19.8611 16.25L24.8444 17.2611C25.1815 17.3333 25.4583 17.4956 25.675 17.7479C25.8917 18.0011 26 18.2963 26 18.6333V24.4833C26 24.9167 25.8556 25.2778 25.5667 25.5667C25.2778 25.8556 24.9167 26 24.4833 26Z"
                          fill="black"
                        />
                      </svg>
                    </div>

                    <div>
                      <Input
                        className="input"
                        disableUnderline
                        type="text"
                        name="phone"
                        sx={{ fontSize: isMobile ? 10 : 14 }}
                        placeholder="Enter Your Mobile"
                        fullWidth={true}
                        onChange={handleChangeInputValue}
                      />
                    </div>
                  </div>

                  {inputError?.phone_error && (
                    <Typography
                      variant="caption"
                      sx={{
                        color: "red",
                        width: "100%",
                        textAlign: "left"
                      }}
                    >
                      {inputError?.phone_error}
                    </Typography>
                  )}


                  <div style={{ marginTop: 50, marginBottom: "35px" }}>
                    <Button
                      className="funnel-button"
                      onClick={funnelDetailSubmit}
                    >
                      DOWNLOAD Now
                    </Button>
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <QRCode value={window.location.href} size={100} bgColor="#FFFFFF" fgColor="#000000" />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

        </div>





      </div>


      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setOpen(false);
              }}
            >
              x
            </IconButton>
          </React.Fragment>
        }
        autoHideDuration={6000}
        sx={{ zIndex: 100 }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>

          Thanks for information will be in touch soon.
        </Alert>
      </Snackbar>
    </div >
  );
}

export default FunnelPage2;
